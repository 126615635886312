input.form-control.form-focus:focus {
  box-shadow: none;
}
.form-focus:focus {
  border: none;
  box-shadow: 0px 5px 10px 0px #0000000D;
}
.form-focus:focus-visible {
  border: none;
  outline: none;
}
input.form-control.form-focus::placeholder {
  color: #6d6f73;
}
.chatbox {
  text-align: center;
  width: 100%;
  border-radius: 13px;
  input {
    width: 100%;
  }
  .form-control {
    padding: 12px 60px 12px 24px;
    font-family: leJeuneDeck;
    font-size: 18px;
    line-height: 24px;
    border-radius: 13px;
    border: none;
    max-height: 170px;
    min-height: 60px;
    resize: none;
    position: absolute;
    bottom: -55px;
    top: auto;
    overflow: auto;
  }
  .chat {
    padding-top: 16px !important;
    z-index: 3;
  }
  .form-control:focus {
    box-shadow: none;
  }
  span {
    position: absolute;
    padding: 12px;
    right: 15px;
  }

  .send-arrow-position {
    position: absolute;
    z-index: 3 !important;
  }

  .send-arrow-position.enabled {
    cursor: pointer;
    border: none;
  }

  .send-arrow-position.disabled {
    border: none;
    background: none !important;
    cursor: default;
  }
  .textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
  }
}
.suggested-prompts-spacing{
  position: relative;
  bottom: -1px;
}
.warning-spacing{
  position: relative;
  bottom: -13px !important;
}
.suggested-warning-spacing{
  position: relative;
  bottom: -1px !important;
}
.homepageresize {
  height: calc(100vh - 89px) !important;
}

.homepage {
  height: calc(100vh - 89px);
  overflow: unset;
  .chatwindow {
    font-family: leJeuneDeck;
    .conversationlist {
      height: calc(100vh - 109px);
      .min-height {
        min-height: calc(100vh - 123px);
      }
    }
    .history-conversationlist {
      height: calc(100vh - 0px);
    }
  }
  .chatbox {
    background: linear-gradient(#e4d8c9, #ffffff);
    text-align: center;
    width: 100%;
    bottom: 0;
    input {
      width: 100%;
    }
    span {
      position: absolute;
      cursor: pointer;
      padding: 12px;
      right: 0;
    }
  }
}

.help-links {
  margin-top: 4px;
  overflow-x: auto !important;
}

.help-links::-webkit-scrollbar {
  display: none !important;
}

.send-arrow-position {
  position: absolute;
  right: 15px;
}

.send-arrow-position.enabled {
  cursor: pointer;
  border: none;
  background: none;
}

.send-arrow-position.disabled {
  border: none;
  background: none;
}

.ms-20 {
  margin-left: 0px;
}

.footer-tags::-webkit-scrollbar {
  display: none;
}
.last-btn{
  margin: 0px 12px 16px 0px !important;
}
.footer_btn {
  border: 2px;
  border-radius: 5px;
  // white-space: nowrap;
  text-align: start;
  border: 1px solid #36373A;
  background: rgba(255, 255, 255, 0);
  padding: 11px 14px 11px 14px;
  color: var(--polo-primary, #041e3a);
  font-family: foundersGroteskTextRegular;
  font-size: 12px;
  line-height: 14px;
  transition: background-color 0.3s ease-out;
  width: fit-content;
}

.footer_search {
  font-family: leJeuneDeck;
}

.page-container {
  overflow-y: auto;
}
.page-container::-webkit-scrollbar {
  display: none !important;
}

#textarea_id:disabled {
  background-color: #ffffff;
}
.stop-icon {
  width: 10px;
  height: 10px;
  border-radius: 2px;
}

.stop-text {
  font-size: 12px;
  line-height: 22px;
  transform: translateY(2.5%);
}

.stop_btn {
  background: #fff;
  border: 1px solid #041e3a59;
  border-radius: 6px;
  padding: 8px 16px;
  color: var(--polo-primary, #041e3a);
  gap: 12px;
  position: sticky !important;
}

.follow_btn {
  display: none !important;
}

.chat-input {
  min-height: 44px !important;
  max-height: 132px !important;
  border-radius: 22px !important;
  border: none !important;
  box-shadow: 0px 5px 10px 0px #0000000D !important;
  padding: 13px 50px 12px 20px !important;
  font-family: leJeuneDeck;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  resize: none;
  position: absolute;
  top: auto;
  bottom : -45px;
  z-index: 3 !important;
}
.submit-arrow{
  background: none;
  border: none;
  z-index: 3;
  padding: 7px;
  position: absolute;
  right: 0px;
  top: 1px;
}
// loader css
.dots{
  display: flex;
  align-items: center;
  transform: translateY(4%);
}
.Commentdots{
  font-family: foundersGroteskTextRegular;
  font-size: 13px;
  line-height: 15px;
  color: #595A5A;
  align-items: center;
}
.loading-dots {
  display: flex;
  align-items: center;
  position: relative;
  width: 22px; 
  height: 22px;
  margin-right: 6px;
 
  // img {
  //   animation: blink 1.4s infinite both;
  //   display: inline-block;
  //   width: 100%; 
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }
 
  // img:nth-child(1) {
  //   animation-delay: 0s;
  // }
 
  // img:nth-child(2) {
  //   animation-delay: 0.2s;
  // }
 
  // img:nth-child(3) {
  //   animation-delay: 0.4s;
  // }

  @keyframes blinkStar {
    // 0%,100%{opacity: 1;}
    // 50%{opacity: 0.2;}
    // 0%, 33% { opacity: 1; }
    // 66%, 100% { opacity: 0.2; }
 
    0%, 20%, 80%, 100% { opacity: 0.2; } /* Low opacity when not highlighted */
    50% { opacity: 1; } /* High opacity when highlighted */
 
    // 0%, 33%,100% { opacity: 1; }
    // 66%, 80%, 90% { opacity: 0.2; }
 
   
  }
 
  .star{
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: blinkStar 3s infinite ease-in-out;;
  }
 
  .star:nth-child(1) {
    animation-delay: 0s;
  }
 
  .star:nth-child(2) {
    animation-delay: 1s;
  }
 
  .star:nth-child(3) {
    animation-delay: 2s;
  }
 
  span {
    animation: blink 1.4s infinite both;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 0;
  }
 
  span:nth-child(1) {
    animation-delay: 0s;
  }
 
  span:nth-child(2) {
    animation-delay: 0.2s;
  }
 
  span:nth-child(3) {
    animation-delay: 0.4s;
  }
}
 
@keyframes blink {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.scrollbar {
  overflow: hidden !important;
}

//********************************//********************************
//************   Media Query     *************//
//********************************//********************************

@media screen and (max-width: 430px) {
  //iphone fix
  .ios-chat-arrow {
    transform: translateY(50%) !important;
    right: 4px !important;
  }
  //screen responsiveness fix
  .res_homepageresize {
    height: calc(100vh - 81px) !important;
  }
  .res_homepage {
    height: calc(100vh - 81px) !important;
  }
  .chatwindow {
    .res_conversationlist {
      height: calc(100vh - 61px) !important;
    }
    .conversationlist {
      .res_min-height {
        min-height: calc(100vh - 126px) !important;
      }
    }
  }
  .m-chat-arrow {
    transform: translateY(20%) !important;
    right: 5px !important;
  }
}

@media screen and (max-width: 576px) {
  .homepage {
    .chatwindow {
      .conversationlist {
        .min-height {
          min-height: calc(100vh - 114px);
        }
      }
    }
  }
  .footer-container {
    padding: 0px 20px;
  }
  .last-footer-btn {
    margin: 0px 12px 16px 0px !important;
  }
  .chat-input {
    // padding-top: 3.9% !important;
    padding-top: 13px !important;
  }
  .ioschatinput{
    padding-top: 15px !important; // for iphone
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .homepage {
    .chatwindow {
      .conversationlist {
        .min-height {
          min-height: calc(100vh - 115px);
        }
      }
    }
  }
  .last-footer-btn {
    margin: 0px 12px 16px 0px !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 959px) {
  .homepage {
    .chatwindow {
      .conversationlist {
        .min-height {
          min-height: calc(100vh - 115px);
        }
      }
    }
  }
}

@media screen and (min-width: 959px) {
  .footer_btn:hover {
    border-radius: 5px;
    border: 1px solid rgba(4, 30, 58, 0);
    background: #fff;
  }
}

@media screen and (max-width: 959px) {
  .chatbox {
    .form-control {
      padding: 15px 40px 15px 18px;
      border-radius: 10px;
      font-size: 14px;
      line-height: 18px;
      min-height: 50px !important;
      max-height: 132px !important;
      bottom: -48px;
    }
  }
  .homepageresize {
    height: calc(100vh - 77px) !important;
  }
  .homepage {
    height: calc(100vh - 77px);
    .chatwindow {
      .conversationlist {
        height: calc(100vh - 101px);
      }
    }
  }
  .help-links {
    margin-top: 3px !important;
  }
  .footer_btn {
    padding: 8px 12px 8px 12px;
    margin: 0px 12px 12px 0px;
  }
  .footer_search {
    padding-bottom: 16px;
  }
  .arrow_long {
    height: 22px;
    width: 22px;
  }
  .footer-gradient {
    height: 16px;
    background: linear-gradient(0deg, #F2F3F5 0%, rgba(242, 243, 245, 0) 100%);
    position: sticky;
    z-index: 3;
  }
  .chat-arrow {
    transform: translateY(30%) !important;
    right: 12px !important;
  }
  .stop-text {
    font-size: 11px !important;
    margin-top: -1px;
    transform: translateY(4%);
  }
  .stop-icon {
    width: 9px;
    height: 9px;
  }
  .stop_btn {
    padding: 6px 12px;
    border-radius: 4px;
  }
}

@media screen and (max-width: 767px) {
  .homepage {
    .chatwindow {
      .conversationlist {
        padding: 0px 16px;
      }
    }
  }
  // .ms-20 {
  //   margin-left: 20px !important;
  // }
  .footer-tags {
    overflow-x: auto;
    float: left;
    // height: 50px;
  }
  .page-container {
    overflow-y: auto;
  }
  .history-container {
    padding: 0px 16px;
  }
}

@media screen and (min-width: 577px) and (max-width: 767px) {
  .footer_search {
    .footer-tags {
      // height: 60px;
      flex-wrap: wrap;
      flex-direction: column;
      overflow-x: auto;
    }
    .footer-tags::-webkit-scrollbar {
      display: none;
    }
  }
}

@media screen and (min-width: 992px) {
  // .footer-tags {
  //   margin-bottom: 8px;
  // }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .footer-tags {
    margin-bottom: 4px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .ms-20 {
    margin-left: 12px !important;
  }
}

@media screen and (max-width: 430px) {
  .chatbox {
    .chat {
      padding-top: 17px !important;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .chatbox {
    .chat {
      padding-top: 2% !important;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .chatbox {
    .chat {
      padding-top: 1.6% !important;
    }
  }
}
@media screen and (min-width: 1400px) {
  .chatbox {
    .chat {
      padding-top: 1.4% !important;
    }
  }
}

@media screen and (min-width: 768px) {
  .footer-tags {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

// @media screen and (max-width: 768px) {
//   .help-links {
//     margin-left: -16px !important;
//   }
// }
// @media screen and (max-width: 444px) {
//   .help-links {
//     width: 107.5% !important;
//   }
// }
// @media screen and (min-width: 445px) and (max-width: 489px) {
//   .help-links {
//     width: 107% !important;
//   }
// }
// @media screen and (min-width: 490px) and (max-width: 547px) {
//   .help-links {
//     width: 106% !important;
//   }
// }
// @media screen and (min-width: 548px) and (max-width: 600px) {
//   .help-links {
//     width: 105.5% !important;
//   }
// }
// @media screen and (min-width: 601px) and (max-width: 711px) {
//   .help-links {
//     width: 104% !important;
//   }
// }
// @media screen and (min-width: 712px) and (max-width: 768px) {
//   .help-links {
//     width: 104% !important;
//   }
// }
.last-btn{
  margin: 0px 12px 16px 0px !important;
}

@media screen and (min-width: 768px) and (max-width: 959px) {
  .footer_btn {
    white-space: pre-wrap !important;
    margin: 0px 12px 12px 0px !important;
  }
}

@media screen and (min-width: 960px) {
  .footer_btn {
    white-space: pre-wrap !important;
    margin: 0px 12px 12px 0px;
  }
  .footer_search {
    padding-bottom: 24px;
  }
  .footer-gradient {
    height: 20px;
    background: linear-gradient(0deg, #F2F3F5 0%, rgba(242, 243, 245, 0) 100%);
    position: sticky;
  }
  .chat-arrow {
    transform: translateY(1.5%) !important;
    right: 12px !important;
    padding: 6px 14px !important;
  }
  .chat-arrow:hover {
    padding: 0.5px 14px 0.5px 14px !important;
    top: 6.4px !important;
    background: #f2f3f5 !important;
    border-radius: 10px;
    cursor: pointer;
  }
  .stop_btn:hover {
    border: 1px solid #041e3a;
  }
  .stop_btn:hover:active {
    // for safari
    border: 1px solid #041e3a;
  }
}
// on iphone keyboard active
@media screen and (max-width: 430px) and (max-height: 550px) {
  .chat-input {
    bottom: -42px !important;
  }
  .submit-arrow {
    padding-top: 3.5px !important;
  }
  .submit-arrow-ios{
    right: -2px !important;
  }
  .warning-spacing {
    position: relative;
    bottom: -8px !important;
  }
}
@media screen and (min-height: 551px) and (max-height: 815px) {
  .warning-spacing{
    position: relative;
    bottom: -27px !important;
  }
}
