.single-look-template {
  border-radius: 20px;
  padding: 40px 30px 20px 30px;
  background-color: #ffffff;
  box-shadow: 0px 5px 10px 0px #0000000d;
  margin: -230px 0px 40px 0px;
  @media screen and (max-width: 375px) {
    margin: -230px 0px 40px 0px !important;
  }
  @media screen and (min-width: 376px) and (max-width: 440px) {
    margin: -290px 0px 40px 0px !important;
  }
  @media screen and (min-width: 441px) and (max-width: 576px){
    margin: -450px 0px 40px 0px !important;
  }
}
.look-title {
  font-family: leJeuneDeck;
  font-size: 26px;
  line-height: 38px;
  letter-spacing: 0px;
  color: #041e3a;
  text-transform: capitalize;
  margin-bottom: 6px;
}
.look-description {
  font-family: foundersGroteskTextRegular;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #041e3a;
  margin-top: 10px;
}
.single-look-carousel {
  margin-top: 30px;
}
// .swiper.swiper-cards {
//  margin-top: "65%" !important;
// }
.product-title {
  font-family: leJeuneDeck;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0px;
  color: #041e3a;
  margin: 30px 0px 5px 0px;
  text-transform: capitalize;
}
.price {
  font-family: foundersGroteskTextRegular;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0px;
  margin-bottom: 12px;
  color: #041e3a;
}
.product-description {
  font-family: foundersGroteskTextRegular;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #041e3a;
  margin-bottom:20px;
}
.utility_btn {
  border-radius: 33px;
  padding: 11px 16px 11px 14px;
  background: #2a2b2d;
  box-shadow: 0px 3px 8px 0px #00000008;
  border: none;
  height: 33px;
  width: 100%
}
.utility-text {
  margin: 1px 0 1.5px 7px;
  font-family: foundersGroteskTextMedium;
  font-size: 9px;
  line-height: 11.43px;
  letter-spacing: 1px;
  color: #ffffff;
  text-transform: uppercase;
}
.swipperCards{
  margin: 25px 0px 24px 0px !important;
}
.custom-slide{
  border-radius: 0 !important;
  border: none !important;
  background: transparent !important;
}
.carousel-img-bg{
  background: #f0efed;
}

// Media Queries
@media screen and (min-width: 375px) {
  .utility_btn {
    min-width: 137.5px;
  }
}

 
  // .swiper {
  //   height: 351px;
  //   width: 257px;
  // }
 
  // .swiper-slide {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   border-radius: 18px;
  //   font-size: 22px;
  //   font-weight: bold;
  //   color: #fff;
  // }
 
