.fadeOutPromts {
  animation: fadeOutPromts 100ms ease-in;
  }

@keyframes fadeOutPromts {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
 
  90% {
    opacity: 0;
  }
 
  100% {
    opacity: 1;
  }
}

.suggested-prompts-fadein {
  animation: suggested-prompts-fadein 1.3s forwards;
  animation-delay: 1.3s;
  opacity: 0;
  @keyframes suggested-prompts-fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}