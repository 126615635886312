.specificProduct {
    padding-top: 0 !important;
    @media screen and (min-width: 577px) and (max-width: 767px){
      margin-left: -10px;
    }
  }
  .product-titles {
    font-family: leJeuneDeck;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0px;
    color: #041e3a;
    margin: 30px 0px 5px 0px;
    text-transform: capitalize;
  }
  .prices {
    font-family: foundersGroteskTextRegular;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0px;
    margin-bottom: 12px;
    color:#041E3A
  }
  
  
  .specific{
   .card{
    display:flex;
    justify-content: center;
    align-items: flex-start;
    // position: absolute;
    border-radius: 20px !important;
    padding: 40px 30px 24px 30px;
    margin-bottom: 40px;
    // margin-top: -8rem;
    border: none !important;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.0509803922);
   }
   .s_icon{
    width: 10.77px;
    height: 10.44px;
    margin-right: 4px;
   }

   .shop_icon{
    width: 13px;
    height: 14px;
    margin-right: 4px;
   }
    
  .specific_text{
    font-family: foundersGroteskTextRegular;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0px;
    text-align:justify;
  }


  .kind_text{
    font-family: leJeuneDeck;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0px;
  }

  .price_tag{
    font-family: foundersGroteskTextRegular;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0px;     
  }

  .btn_main{
    display: flex;
    justify-content: space-between;
    width:100%;
  }

  .btn_outer{
    border-radius: 33px;
    padding: 11px 16px 11px 14px;
    background: #2a2b2d;
    box-shadow: 0px 3px 8px 0px #00000008;
    border: none;
    height: 33px;
    width:137.5px;
   }

  .btn_text{
    font-family: foundersGroteskTextMedium;
    font-size: 9px;
    line-height: 11.43px;
    letter-spacing: 1px;
    color: #ffffff;
    text-transform: uppercase;
  }
  

  // Media Query

      @media screen and (max-width: 576px) {
        .card {
          margin-top: 0;
        }
        .btn_mr{
          margin-left: 8px;
         }
      }

      @media screen and (max-width: 959px) {
        p {
            margin-bottom: 6px !important;
        }
    }
  }
